import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
    InputNumber,
    Modal,
    Switch,
    Table,
    Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ColumnType } from 'antd/lib/table';
import { AppDispatch, RootState } from '../../configureStore';
import {
    FlagState,
    FLAG_MODAL_OPEN,
    UPDATE_FLAG,
    SUMMARIZE_WITH_ADD,
} from '../../redux/reducer/analyzer';
import { Fields, Flag, FlagWithValue } from '../../utils/interfaces/halcyon360';
import { formatDollar } from '../../utils/helpers';

interface FlagModalParams {
}

const FlagModal = ({
}: FlagModalParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        open,
        fieldId,
        year,
        location,
    } = useSelector<RootState, FlagState>(({ analyzer: { flagModal } }) => flagModal);
    const fields = useSelector<RootState, Fields>(({ analyzer: { calculation, activeBorrower } }) => calculation.data[activeBorrower || '']?.fields || {});
    const [changed, setChanged] = useState<boolean>(false);

    const flags = useMemo<FlagWithValue[]>(() => {
        if (fieldId != null && year != null) {
            const field = fields[fieldId];
            return (field?.values?.[year]?.flag || [])?.map((f) => ({
                ...f,
                newValue: f.multiplier * (field.values[year].value || 1),
                value: field.values[year].value,
            })) ?? [];
        }
        return [];
    }, [fields, fieldId, year]);

    useEffect(() => {
        if (!open) setChanged(false);
    }, [open]);

    const setOpen = () => {
        dispatch({
            type: FLAG_MODAL_OPEN,
            location: undefined,
            fieldId: undefined,
            yearIndex: undefined,
        });
    };

    const updateFlag = (index: number, flag: Flag, auditType: 'flag-active' | 'flag-multiplier') => {
        setChanged(true);
        dispatch({
            type: UPDATE_FLAG,
            index,
            flag,
            fieldId,
            year: flag.year,
            auditType,
        });
    };

    const cols = useMemo<ColumnType<FlagWithValue>[]>(() => [
        {
            title: 'Reason',
            dataIndex: 'title',
        },
        {
            title: 'Year',
            dataIndex: 'year',
        },
        {
            title: 'Value on Form',
            dataIndex: 'value',
            render: (v) => formatDollar(v),
        },
        {
            title: 'Ratio',
            dataIndex: 'multiplier',
            render: (m, fl, index) => (
                <InputNumber
                    value={m}
                    step={0.1}
                    max={1}
                    min={0}
                    onChange={(v) => {
                        updateFlag(index, { ...fl, multiplier: v }, 'flag-multiplier');
                    }}
                />
            ),
        },
        {
            title: 'Used Value',
            dataIndex: 'newValue',
            render: (v) => formatDollar(v),
        },
        {
            title: 'Active',
            dataIndex: 'active',
            render: (a, fl, index) => (
                <Switch
                    checked={a}
                    onChange={(checked) => {
                        updateFlag(index, { ...fl, active: checked }, 'flag-active');
                    }}
                />
            ),
        },
    ], [fieldId]);

    return (
        <Modal
            open={open}
            destroyOnClose
            onCancel={() => {
                setOpen();
                if (changed) dispatch({ type: SUMMARIZE_WITH_ADD, location: location ?? [] });
            }}
            footer={null}
            width="80%"
        >
            <Typography.Title level={4}>Adjustments to Field</Typography.Title>
            <Table
                className="mt-6"
                bordered
                dataSource={flags}
                columns={cols}
                rowKey="id"
            />
        </Modal>
    );
};

export default FlagModal;
