import { Form } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BusinessTranscript from "../molecules/BusinessTranscript";
import { RootState } from "../../configureStore";

interface BusinessTranscriptsParams {
    remove: (index: number) => void;
    display?: boolean;
}

export const DEFAULT_BUSINESS = {
    businessName: undefined,
    businessEmail: undefined,
    ein: undefined,
    addressLineOne: undefined,
    addressLineTwo: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    title: undefined,
    eSignEnabled: undefined,
    yearList: [0],
    multi: 'Last 2 Years',
};

const BusinessTranscripts = ({
    remove,
    display = true,
}: BusinessTranscriptsParams) => {
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const [open, setOpen] = useState<Set<number>>((isAdd || isEdit) ? new Set<number>([0]) : new Set<number>());

    useEffect(() => {
        if (isAdd || isEdit) setOpen(new Set<number>([0]));
    }, [isAdd, isEdit]);

    return !display ? (
        <div style={{ display: 'none' }} />
    ) : (
        <Form.List name="business">
            {(fields, { add }) => (
                <BusinessTranscript
                    open={open}
                    onOpenChange={(field) => {
                        const newOpen = new Set([...open]);
                        if (open.has(field.name)) {
                            newOpen.delete(field.name);
                        } else {
                            newOpen.add(field.name);
                        }
                        setOpen(newOpen);
                    }}
                    fields={fields}
                    change={(action, toRemove) => {
                        const newOpen = new Set([...open]);
                        switch (action) {
                            case "add":
                                add(DEFAULT_BUSINESS);
                                newOpen.add(fields.length);

                                break;
                            case "remove":
                                if (toRemove) {
                                    newOpen.delete(toRemove.name);
                                    remove(toRemove.name);
                                }
                                break;
                            default:
                                console.error('What happened?');
                                break;
                        }

                        setOpen(newOpen);
                    }}
                />
            )}
        </Form.List>
    );
};

export default BusinessTranscripts;
