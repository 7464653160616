import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, ProxyFile, UpdateTransactionOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";
import { IBorrower } from "../interfaces/borrower";
import { ITranscriptRequest } from "../interfaces/create";

export class UpdateTransaction extends Transaction<UpdateTransactionOptions> {
    constructor(
        borrower: IBorrower,
        values: ITranscriptRequest,
        origin: APIOrigin,
        hasAccountChange: boolean,
    ) {
        const { loan } = origin.origin;

        const update: UpdateTransactionOptions = {
            individual: (values.individual ?? []).filter((brwr) => (
                brwr.firstName !== borrower.firstName
                || brwr.lastName !== borrower.lastName
                || brwr.tin !== borrower.tin
                || brwr.email !== borrower.email
                || brwr.addressLineOne !== borrower.addressLineOne
                || brwr.addressLineTwo !== borrower.addressLineTwo
                || brwr.city !== borrower.city
                || brwr.postalCode !== borrower.postalCode
                || brwr.state !== borrower.state
                || brwr?.transcriptAuthorization?.length
                || brwr?.governmentIssuedPhotoId?.length
                || hasAccountChange
                || brwr.eSignEnabled !== borrower.eSignEnabled
            )).map((ind) => ({
                irsIncomeId: ind.irsIncomeId,
                tin: ind.tin,
                firstName: ind.firstName,
                lastName: ind.lastName,
                email: ind.email,
                addressLine1: ind.addressLineOne,
                addressLine2: ind.addressLineTwo,
                city: ind.city,
                state: ind.state,
                postalCode: ind.postalCode,
                taxYears: [],
                files: Transaction.getIndividualProxyFiles(ind),
                verifyTIN: ind.verifyTIN,
                eSignEnabled: ind.eSignEnabled,
            })),
            business: (values.business ?? []).filter((biz) => (
                biz.businessName !== borrower?.businessInformation?.businessName
                || biz.businessEmail !== borrower?.businessInformation?.email
                || biz.addressLineOne !== borrower?.addressLineOne
                || biz.addressLineTwo !== borrower?.addressLineTwo
                || biz.city !== borrower?.city
                || biz.state !== borrower?.state
                || biz.postalCode !== borrower?.postalCode
                || biz.firstName !== borrower?.firstName
                || biz.lastName !== borrower?.lastName
                || biz.title !== borrower?.title
                || biz.eSignEnabled !== borrower?.eSignEnabled
                || biz.ein !== borrower?.businessInformation?.ein
                || biz.isSamePerson !== borrower?.businessInformation?.isSamePerson
                || biz?.businessTranscriptAuthorization?.length
                || biz?.businessGovernmentIssuedPhotoId?.length
                || hasAccountChange
            )).map((biz) => ({
                irsIncomeId: biz.irsIncomeId,
                businessName: biz.businessName,
                email: biz.businessEmail,
                addressLine1: biz.addressLineOne,
                addressLine2: biz.addressLineTwo,
                city: biz.city,
                state: biz.state,
                postalCode: biz.postalCode,
                firstName: biz.firstName,
                lastName: biz.lastName,
                title: biz.title,
                eSignEnabled: biz.eSignEnabled,
                ein: biz.ein,
                taxYears: [],
                files: Transaction.getBusinessProxyFiles(biz),
                isSamePerson: biz.isSamePerson || false,
                verifyTIN: biz.verifyTIN,
            })),
            loanProfessional: origin.origin.originatingParty.pointOfContact,
            hasIRSAccount: typeof values.hasIRS === 'string' ? values.hasIRS === 'Yes' : values.hasIRS || false,
            hasPOA: false,
            type: "IRS Wage and Income",
            requestType: "Request Updated",
            instanceId: origin.instanceId,
            loanNumber: loan.loanNumber,
        };

        const proxyFiles: ProxyFile[] = update.individual.flatMap(({ files }) => files).concat(update.business.flatMap(({ files }) => files)).filter(({ id }) => id);

        super(
            borrower.encompassBorrowerId,
            update,
            proxyFiles,
        );
    }

    public toTransaction(): TransactionRequest<UpdateTransactionOptions> {
        return super.toTransaction();
    }
}
