import { IBorrower } from "../interfaces/borrower";
import { ITranscriptRequest } from "../interfaces/create";
import { APIOrigin, AddedOptions, ProxyBusiness, ProxyFile, ProxyIndividual } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class AddedTransaction extends Transaction<AddedOptions> {
    constructor(
        borrower: IBorrower,
        values: ITranscriptRequest,
        origin: APIOrigin,
    ) {
        const { loan } = origin.origin;
        const individualAdditions: ProxyIndividual[] = [];

        (values.individual ?? []).forEach((ind) => {
            if (!ind.irsIncomeId) {
                individualAdditions.push({
                    irsIncomeId: ind.irsIncomeId,
                    tin: ind.tin,
                    firstName: ind.firstName,
                    lastName: ind.lastName,
                    email: ind.email,
                    addressLine1: ind.addressLineOne,
                    addressLine2: ind.addressLineTwo,
                    city: ind.city,
                    state: ind.state,
                    postalCode: ind.postalCode,
                    taxYears: Transaction.getTaxYears(ind.yearList, ind.formList ?? [], ind.multi),
                    files: Transaction.getIndividualProxyFiles(ind),
                    verifyTIN: false,
                    eSignEnabled: ind.eSignEnabled,
                });
            } else if (ind.yearList.length) {
                individualAdditions.push({
                    irsIncomeId: ind.irsIncomeId,
                    tin: ind.tin,
                    firstName: ind.firstName,
                    lastName: ind.lastName,
                    email: ind.email,
                    addressLine1: ind.addressLineOne,
                    addressLine2: ind.addressLineTwo,
                    city: ind.city,
                    state: ind.state,
                    postalCode: ind.postalCode,
                    taxYears: Transaction.getTaxYears(ind.yearList, ind.formList ?? [], ind.multi),
                    files: [],
                    verifyTIN: false,
                    eSignEnabled: ind.eSignEnabled,
                });
            }
        });

        const business: ProxyBusiness[] = [];
        const businessAdditions: ProxyBusiness[] = [];

        (values.business ?? []).forEach((biz) => {
            if (!biz.irsIncomeId) {
                business.push({
                    businessName: biz.businessName,
                    email: biz.businessEmail,
                    addressLine1: biz.addressLineOne,
                    addressLine2: biz.addressLineTwo,
                    city: biz.city,
                    state: biz.state,
                    postalCode: biz.postalCode,
                    ein: biz.ein,
                    firstName: biz.firstName,
                    lastName: biz.lastName,
                    title: biz.title,
                    eSignEnabled: biz.eSignEnabled,
                    taxYears: Transaction.getTaxYears(biz.yearList, biz.formList ?? [], biz.multi),
                    files: Transaction.getBusinessProxyFiles(biz),
                    isSamePerson: biz.isSamePerson || false,
                    verifyTIN: false,
                });
            } else if (biz.yearList.length) {
                businessAdditions.push({
                    irsIncomeId: biz.irsIncomeId,
                    businessName: biz.businessName,
                    email: biz.businessEmail,
                    addressLine1: biz.addressLineOne,
                    addressLine2: biz.addressLineTwo,
                    city: biz.city,
                    state: biz.state,
                    postalCode: biz.postalCode,
                    firstName: biz.firstName,
                    lastName: biz.lastName,
                    title: biz.title,
                    eSignEnabled: biz.eSignEnabled,
                    ein: biz.ein,
                    taxYears: Transaction.getTaxYears(biz.yearList, biz.formList ?? [], biz.multi),
                    files: [],
                    isSamePerson: biz.isSamePerson || false,
                    verifyTIN: false,
                });
            }
        });

        const addition: AddedOptions = {
            loanNumber: origin.origin.loan.loanNumber,
            loanProfessional: origin.origin.originatingParty.pointOfContact,
            requestType: "Request Append",
            instanceId: origin.instanceId,
            business,
            businessAdditions,
            individualAdditions,
            encompassBorrowerId: borrower.id,
            primaryBorrower: origin.origin.loan.applications[0].borrower.id,
            type: "IRS Wage and Income",
            contacts: loan.contacts || [],
            channel: loan.channel,
        };

        const proxyFiles: ProxyFile[] = addition.business.flatMap(({ files }) => files).concat((addition.individualAdditions ?? []).flatMap(({ files }) => files));

        super(
            borrower.encompassBorrowerId,
            addition,
            proxyFiles,
        );
    }
}
