import {
    Button,
    Checkbox,
    Col,
    Form,
    Row,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from '../atoms/FormItem';
import { AppDispatch, RootState } from '../../configureStore';
import { Calculation, CurrentAudit, FormHash, SaveCalculatorResponse } from '../../utils/interfaces/halcyon360';
import { saveCalculation } from '../../redux/reducer/analyzer';
import { TransactionOrigin } from '../../utils/interfaces/encompass';

interface IForm {
    name: string;
    notes: string;
    lock: boolean;
}

interface SaveCalculatorModalParams {
    loanNumber: string;
    setOpen: (_: boolean) => void;
    changeCalculator: (s: string) => void;
}

const SaveCalculatorModal = ({
    setOpen,
    loanNumber,
    changeCalculator,
}: SaveCalculatorModalParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const calc = useSelector<RootState, Calculation>(({ analyzer: { calculation } }) => calculation);
    const formHashes = useSelector<RootState, FormHash>(({ analyzer }) => analyzer.formHashes);
    const iii = useSelector<RootState, string>(({ analyzer: { activeBorrower } }) => activeBorrower || '');
    const current = useSelector<RootState, CurrentAudit[]>(({ analyzer: { currentUpdates } }) => Object.values(currentUpdates).flatMap((cu) => Object.values(cu)));
    const saving = useSelector<RootState, boolean>(({ status: { loading } }) => loading['analyzer/SAVE_CALCULATORS']);
    const [form] = useForm<IForm>();

    return (
        <Form
            form={form}
            className="pa-8"
            onFinish={(vals: IForm) => {
                let updates: CurrentAudit[] = current;
                if (vals.name !== calc.name) {
                    updates = [
                        ...current,
                        {
                            date: new Date(),
                            iii,
                            type: 'filename',
                            fieldId: 'root',
                            value: vals.name,
                        },
                    ];
                }
                dispatch(saveCalculation({
                    input: {
                        OriginId: txnOrgn?.id || '',
                        PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                        calculator: {
                            calculator: calc,
                            loanNumber,
                            lockFile: vals.lock,
                            oldId: calc.calculatorId,
                            name: vals.name,
                            updates,
                            notes: vals.notes,
                            formHashes,
                        },
                    },
                    onSuccess: (res: SaveCalculatorResponse) => {
                        changeCalculator(res.data.calculator.calculatorId);
                        setOpen(false);
                    },
                }));
            }}
            initialValues={{
                lock: calc.isLocked,
                name: calc.name,
                notes: '',
            }}
        >
            <Row gutter={8}>
                <Col span={24}>
                    <FormItem
                        label="Name"
                        required
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        name="notes"
                    >
                        <TextArea placeholder="Notes" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={12}>
                    <Form.Item name="lock" valuePropName="checked">
                        <Checkbox>Lock File</Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Row gutter={8} align="middle" justify="end">
                        <Col span={6} offset={6}><Button type="text" onClick={() => { setOpen(false); }}>Cancel</Button></Col>
                        <Col span={6} offset={6}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saving}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default SaveCalculatorModal;
