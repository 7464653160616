import { useRef } from 'react';
import {
    Col, Divider, Popover, Row, Typography,
} from 'antd';

import { CombinedTaxPayerForms } from '../../utils/interfaces/analyzer';
import Icon from '../atoms/Icon';

interface TaxPayerFormsUsedParams {
    taxPayer: CombinedTaxPayerForms;
    isBusiness: boolean;
}

const TaxPayerFormsUsed = ({
    taxPayer,
    isBusiness: _isBusiness,
}: TaxPayerFormsUsedParams) => {
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <Popover
            placement="bottom"
            overlayClassName="no-arrow-popover"
            content={(
                <div style={{ width: ref?.current?.clientWidth }}>
                    <Row>
                        <Col style={{ fontWeight: 500 }} span={14} className="pl-1">Tax Form</Col>
                        <Col style={{ fontWeight: 500 }} span={10} className="pl-1">Year</Col>
                    </Row>
                    {Object.keys(taxPayer.usedForms).map((frm) => (
                        <div key={`forms-${taxPayer.id}-${frm}`}>
                            <Divider className="my-2" />
                            <Row justify="space-between" align="middle">
                                <Col span={14}>
                                    <Typography className="pl-1">{frm}</Typography>
                                </Col>
                                <Col span={10}>
                                    <Typography className="pl-1">
                                        {taxPayer.usedForms[frm].join(', ')}
                                    </Typography>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            )}
        >
            <Row ref={ref} className="br-sm w100 lightMediumGray pa-3" justify="space-between" align="middle">
                <Typography.Title level={5} style={{ fontSize: '14px' }} className="my-0 mbi-0">{'businessName' in taxPayer ? taxPayer.businessName : taxPayer.name}</Typography.Title>
                <Icon
                    name="RightOutlined"
                />
            </Row>
        </Popover>
    );
};

export default TaxPayerFormsUsed;
