import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Card, Spin, Tooltip } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { AppDispatch, RootState } from "../../configureStore";
import { getCalculatorData, RESET_ANALYZER_STATE, SET_CALCULATOR_BORROWER } from "../../redux/reducer/analyzer";
import { TaxPayer, TaxPayerYear } from "../../utils/interfaces/analyzer";
import { Calculation } from "../../utils/interfaces/halcyon360";
import { TransactionOrigin } from "../../utils/interfaces/encompass";
import Header from "../organisms/Analyzer/Header";
import FormsUsed from "../organisms/FormsUsed";
import Calculator from "../organisms/Analyzer/Calculator";

interface AnalyzerParams {
}

const Analyzer = ({
}: AnalyzerParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);

    const loanNumber = useSelector<RootState, string>(({ encompass: { origin } }) => origin?.origin.loan.loanNumber || '');
    const firstLoading = useSelector<RootState, boolean>(({ status: { loading: l, success: s } }) => ((l['analyzer/GET_ANALYZER'] && !s['analyzer/GET_ANALYZER']) || l['analyzer/GET_ANALYZER'] === undefined));
    const noData = useSelector<RootState, boolean>(({ analyzer: { noData: nd } }) => nd);
    const allBorrowers = useSelector<RootState, { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; }; }>(({ analyzer: { taxPayers } }) => taxPayers);
    const calculators = useSelector<RootState, { [calculatorId: string]: Calculation }>(({ analyzer: { calculators: clcs } }) => clcs);
    const devInfo = useSelector<RootState, { loanNumber: string; institutionName: string; } | undefined>(({ analyzer: { devInfo: di } }) => di);

    useEffect(() => () => {
        dispatch({ type: RESET_ANALYZER_STATE });
    }, []);

    const [calculator, setCalculator] = useState<string>('');

    const setSelectedBorrower = (str: string) => {
        dispatch({ type: SET_CALCULATOR_BORROWER, id: str });
    };

    useEffect(() => {
        if (txnOrgn) {
            dispatch(getCalculatorData({ input: { originId: txnOrgn?.id, partnerAccessToken: txnOrgn?.partnerAccessToken } }));
        }
    }, [txnOrgn]);

    useEffect(() => {
        if (!calculator && Object.values(calculators).length) {
            setCalculator(Object.values(calculators)[0].calculatorId);
        }
    }, [calculators]);

    return (
        <div className="w80" style={{ margin: 'auto' }}>
            {
                (!allBorrowers) ? <LoadingOutlined /> : (
                    <div>
                        {(['dev', 'development', 'qa'].includes(process.env.NODE_ENV || '') && devInfo) && (
                            <div className="flex-end-center" style={{ position: 'absolute', top: 8, right: 8 }}>
                                <Tooltip
                                    placement="left"
                                    title={(
                                        <p className="mb-0">
                                            {devInfo.institutionName}
                                            {': '}
                                            {devInfo.loanNumber}
                                        </p>
                                    )}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>
                        )}
                        <Card
                            style={{ display: 'block' }}
                            className="mb-4 mt-4"
                            title={(
                                <Header
                                    loanNumber={loanNumber || ''}
                                    setCalculator={setCalculator}
                                    noData={noData}
                                />
                            )}
                        >
                            <FormsUsed />
                        </Card>
                        {firstLoading ? (
                            <Spin
                                className="flex-center-center h70"
                                size="large"
                            />
                        ) : noData ? (
                            <div className="flex-center-center col h70">
                                <h2>
                                    We do not have any data available for this Loan!
                                </h2>
                            </div>
                        ) : (
                            <Calculator setSelectedBorrower={setSelectedBorrower} changeCalculator={setCalculator} />
                        )}
                    </div>
                )
            }
        </div>
    );
};

export default Analyzer;
