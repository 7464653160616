/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    Col,
    notification,
    Row,
    Spin,
} from 'antd';

import { AppDispatch, RootState } from '../../../configureStore';
import {
    CHANGE_YEAR,
    saveCalculation,
    YEAR_COUNT,
    setCalculation,
    SUMMARIZE,
    TOGGLE_ANALYZER_HISTORY,
    noSaveCalculation,
    DUPLICATE_DIVISION,
    SELECT_OPTIONAL_FIELD,
} from '../../../redux/reducer/analyzer';
import {
    Calculation,
    Division,
    FormHash,
    IncomeCalculation,
    SaveCalculatorResponse,
    Tints,
    YearFlags,
} from '../../../utils/interfaces/halcyon360';
import Summary from './Summary';
import { TaxPayer, TaxPayerYear } from '../../../utils/interfaces/analyzer';
import { populateCalculator } from '../../../utils/halcyon360.ts';
import BorrowerSelector from '../../molecules/BorrowerSelector';
import { TransactionOrigin } from '../../../utils/interfaces/encompass';
import IncomeCalculationHeader from '../../molecules/IncomeCalculationHeader';
import CalculatorYearAmount from '../../molecules/CalculatorYearAmount';
import CalculatorYearSelects from '../../molecules/CalculatorYearSelects';
import { mapDivisions } from './CalculatorDivision';

interface CalculatorParams {
    setSelectedBorrower: (_d: string) => void;
    changeCalculator: (s: string) => void;
}

const Calculator = ({
    setSelectedBorrower,
    changeCalculator,
}: CalculatorParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);

    const loanNumber = useSelector<RootState, string>(({ encompass }) => encompass.loanNumber || '');
    const borrowers = useSelector<RootState, TaxPayer[]>(({ analyzer: { taxPayers } }) => Object.values(taxPayers).map(({ info }) => info).filter((v) => v.id !== 'all'));
    const calculation = useSelector<RootState, Calculation>(({ analyzer }) => analyzer.calculation);
    const calculatorId = useSelector<RootState, string | undefined>(({ analyzer: { calculatorId: cId } }) => cId);
    const formHashes = useSelector<RootState, FormHash>(({ analyzer }) => analyzer.formHashes);
    const taxPayers = useSelector<RootState, { [id: string]: { info: TaxPayer; data: TaxPayerYear[]; flags: YearFlags; }; }>(({ analyzer: { taxPayers: tp } }) => tp);
    const selectedBorrower = useSelector<RootState, string | undefined>(({ analyzer: { activeBorrower } }) => activeBorrower);
    const dataChanged = useSelector<RootState, boolean>(({ analyzer: { hasChanges } }) => hasChanges);
    const tints = useSelector<RootState, Tints>(({ analyzer: { divisionTints } }) => divisionTints);

    const incomeCalc = useMemo<IncomeCalculation>(() => calculation.data[selectedBorrower || ''], [selectedBorrower, calculation]);

    const setHistoryOpen = (fieldIds: string[], divisionId: string, fieldType?: 'subtotal' | 'factor') => {
        dispatch({
            type: TOGGLE_ANALYZER_HISTORY,
            fieldIds,
            divisionId,
            fieldType,
        });
    };

    useEffect(() => {
        if (dataChanged) {
            notification.info({
                message: calculation.isLocked ? 'New transcripts has been added since this loan was locked' : 'New transcripts has been added since last accessed',
            });
            if (!calculation.isLocked) {
                const setCalc: Calculation = populateCalculator(calculation, taxPayers);
                dispatch(saveCalculation({
                    input: {
                        OriginId: txnOrgn?.id || '',
                        PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                        calculator: {
                            calculator: setCalc,
                            loanNumber,
                            lockFile: false,
                            name: setCalc.name,
                            oldId: calculation.calculatorId,
                            formHashes,
                            updates: [
                                {
                                    date: new Date(),
                                    fieldId: 'forms',
                                    iii: selectedBorrower || '',
                                    type: 'forms',
                                    value: '',
                                },
                            ],
                        },
                    },
                    onSuccess: (res: SaveCalculatorResponse) => {
                        changeCalculator(res.data.calculator.calculatorId);
                        notification.success({
                            message: 'We have successfully updated this calculation!',
                        });
                    },
                }));
            }
        }
    }, [dataChanged]);

    const changeYearCount = (add: boolean) => {
        dispatch({ type: YEAR_COUNT, add });
        dispatch({ type: SUMMARIZE });
    };
    const [solved, setSolved] = useState<boolean>(false);

    useEffect(() => {
        if (solved) setSolved(false);
    }, [calculatorId]);

    useEffect(() => {
        if (Object.keys(calculation || {}).length && calculation.id && calculation.calculatorId !== 'default' && taxPayers && !solved) {
            setSolved(true);
            if (calculation.isDefault) {
                const setCalc: Calculation = populateCalculator(calculation, taxPayers);
                if (process.env.NODE_ENV === 'development') {
                    dispatch(noSaveCalculation(setCalc));
                } else {
                    dispatch(saveCalculation({
                        input: {
                            OriginId: txnOrgn?.id || '',
                            PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                            calculator: {
                                calculator: setCalc,
                                loanNumber,
                                lockFile: false,
                                name: setCalc.name,
                                oldId: calculation.calculatorId,
                                formHashes,
                                updates: [],
                            },
                        },
                        onSuccess: (res: SaveCalculatorResponse) => {
                            changeCalculator(res.data.calculator.calculatorId);
                        },
                    }));
                }
            } else {
                dispatch(setCalculation(calculation));
            }
        }
    }, [calculation.id]);

    const duplicate = useCallback((ancestry: string, division: Division, isRemove: boolean) => {
        dispatch({
            type: DUPLICATE_DIVISION,
            ancestry,
            division,
            isRemove,
        });
    }, [incomeCalc, taxPayers, selectedBorrower]);

    const selectOptionalField = useCallback((ancestry: string, division: Division, addition: Division, isChoice: boolean) => {
        dispatch({
            type: SELECT_OPTIONAL_FIELD,
            ancestry,
            division,
            addition,
            isChoice,
        });
    }, [incomeCalc, taxPayers, selectedBorrower]);

    const divisions = useMemo(() => {
        if (incomeCalc?.divisions) return mapDivisions(incomeCalc.divisions, incomeCalc, 'start', setHistoryOpen, duplicate, selectOptionalField, tints);
        return <div />;
    }, [incomeCalc]);

    return (
        <div>
            {
                incomeCalc ? (
                    <Card
                        style={{ textAlign: 'left' }}
                        className="no-title-border"
                        size="small"
                        classNames={{
                            body: 'pt-0-i',
                            title: 'my-4',
                        }}
                        title={(
                            <IncomeCalculationHeader
                                isLocked={calculation.isLocked || false}
                                createdBy={calculation.createdBy}
                                loanNumber={loanNumber}
                            />
                        )}
                    >
                        <Row gutter={8} align="middle" className="py-1 px-2">
                            <Col xs={13} style={{ display: 'flex', alignItems: 'end' }}>
                                <BorrowerSelector borrowers={borrowers} onChange={setSelectedBorrower} value={selectedBorrower} />
                            </Col>
                            <Col xs={3} className="flex-center-end">
                                <CalculatorYearAmount
                                    usedYears={incomeCalc.usedYears}
                                    availableYears={incomeCalc.years}
                                    changeYearCount={changeYearCount}
                                />
                            </Col>
                            <Col xs={8}>
                                <CalculatorYearSelects
                                    usedYears={incomeCalc.usedYears}
                                    allYears={taxPayers[selectedBorrower || ''].data.map((datum) => datum.year).sort((a, b) => b - a)}
                                    onChange={(e: string, idx: number) => {
                                        try {
                                            dispatch({ type: CHANGE_YEAR, value: e, index: idx });
                                            dispatch({ type: SUMMARIZE });
                                        } catch (er) { console.error(er); }
                                    }}
                                />
                            </Col>
                        </Row>
                        <div key={`${incomeCalc.years[0]}${incomeCalc.years[1]}${selectedBorrower}`}>
                            {divisions}
                        </div>
                        <Summary
                            className="mt-4"
                            summary={incomeCalc.summary}
                            usedYears={incomeCalc.usedYears}
                        />
                    </Card>
                ) : (
                    <Spin
                        className="flex-center-center h60"
                    />
                )
            }
        </div>
    );
};

export default Calculator;
