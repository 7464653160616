import {
    Col,
    Row,
    Select,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useState } from 'react';

import { Division, IncomeCalculation } from '../../utils/interfaces/halcyon360';

interface CalculatorOptionsRowParams {
    options: Division[];
    selectOptionalField: (_ancestry: string, _division: Division, _addition: Division, _isChoice: boolean) => void;
    ancestry: string;
    division: Division;
    calculation: IncomeCalculation;
    tinted: boolean;
    indent?: number;
}

const CalculatorOptionsRow = ({
    options,
    selectOptionalField,
    ancestry,
    division,
    calculation,
    tinted,
    indent = 0,
}: CalculatorOptionsRowParams) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    return (
        <Row gutter={8} className={`pa-2 arow-${indent}${tinted ? ' lightMediumGray' : ''}`}>
            <Col span={16}>
                {'Select additional from dropdown ->'}
            </Col>
            <Col span={8}>
                <Row gutter={8} justify="space-between">
                    <Col span={2} />
                    <Col span={22}>
                        <Select
                            style={{ width: '100%' }}
                            size="small"
                            placeholder="Add optional fields"
                            value={selectedValue}
                            options={options.map<DefaultOptionType>(({ id }) => ({ value: id, label: calculation.fields[id].prefix?.title || calculation.fields[id].title }))}
                            onSelect={(e) => {
                                if (e) {
                                    selectOptionalField(
                                        ancestry,
                                        division,
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        division.options.find(({ id }) => id === e),
                                        false,
                                    );
                                }
                                setSelectedValue(null);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CalculatorOptionsRow;
