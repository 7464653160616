import { useEffect, useState } from 'react';
import { Checkbox, Col, Form, FormListFieldData, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import FormItem from "../atoms/FormItem";
import AddTranscript from "./AddTranscript";
import BusinessInformation from "./BusinessInformation";
import AddressInformation from './AddressInformation';
import FormTypes from "./FormTypes";
import DocumentUpload from "./DocumentUpload";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

interface BusinessFormParams {
    open: Set<number>;
    onOpenChange: (field: FormListFieldData) => void;
    change: (action: 'remove' | 'add', toRemove?: FormListFieldData) => void;
    field: FormListFieldData;
    idx: number;
}

const BusinessForm = ({
    open,
    onOpenChange,
    change,
    field,
    idx,
}: BusinessFormParams) => {
    const eSign = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => borrowers[activeBorrower || '']?.eSignEnabled || false);
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const { isSmall, isMedium } = useWindowDimensions();
    const [eSignEnabled, setIsChecked] = useState<boolean>(false);
    const eSignRequired = useSelector<RootState, boolean>(({ encompass: { origin } }) => Boolean(origin?.eSignEnabledTenant));
    useEffect(() => {
        setIsChecked(eSign);
    }, []);

    return (
        <div key={`business-txpt-${idx}`}>
            <div className="none"><FormItem label="irsIncomeId" index={field.key} /></div>
            {(!isEdit && !isAdd) && (
                <AddTranscript
                    isBusiness
                    onClick={() => { onOpenChange(field); }}
                    onRemove={(isAdd || isEdit) ? undefined : () => { change('remove', field); }}
                    isOpen={open.has(field.name)}
                    businessName={undefined}
                />
            )}
            <div style={{ display: !open.has(field.name) ? 'none' : undefined }}>
                <Form.Item
                    getValueFromEvent={(ev: any) => {
                        setIsChecked(ev.target.checked);
                        return ev.target.checked;
                    }}
                    name={idx != null ? [idx, "eSignEnabled"] : "eSignEnabled"}
                    valuePropName="checked"
                    className="mb-1"
                // style={{ display: (eSignEnabled) ? 'none' : undefined }}
                >
                    {eSignRequired && (
                        <Checkbox>Send to Borrower for Signature</Checkbox>
                    )}
                </Form.Item>
                <BusinessInformation
                    existing={isAdd || isEdit}
                    index={field.key}
                    onNameChange={() => { }}
                    eSignEnabled={eSignEnabled}
                />
                {eSignEnabled && (
                    <Row gutter={8} hidden={!eSignEnabled}>
                        <Col span={12} hidden={!eSignEnabled}>
                            <FormItem
                                label="Borrower First Name"
                                name="firstName"
                                required={eSignEnabled}
                                rules={[{
                                    required: eSignEnabled,
                                    // pattern: /^[A-Za-z\- ]+$/,
                                    message: 'Please only include characters or spaces',
                                }]}
                                index={field.key}
                            />
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="Borrower Last Name"
                                name="lastName"
                                required={eSignEnabled}
                                rules={[{
                                    required: eSignEnabled,
                                    // pattern: /^[A-Za-z\- ]+$/,
                                    message: 'Please only include characters or spaces',
                                }]}
                                index={field.key}
                            />
                        </Col>
                    </Row>
                )}

                <AddressInformation index={field.key} hidden={!eSignEnabled} />
                <Row gutter={8}>
                    <Col className={isEdit ? 'none' : ''} span={12}>
                        <FormTypes
                            existing={isAdd || isEdit}
                            index={field.key}
                            type="Business"
                        />
                    </Col>
                    <Col
                        className={isAdd ? 'none' : ''}
                        span={12}
                    >
                        <DocumentUpload
                            index={field.key}
                            isBusiness
                            isEdit={isEdit}
                            span={isEdit ? isMedium ? 24 : 10 : isSmall ? 24 : 12}
                            eSignEnabled={eSignEnabled}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default BusinessForm;
