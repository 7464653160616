import { Col, Row, Typography } from 'antd';
import Icon from '../atoms/Icon';

interface CalculatorYearAmountParams {
    usedYears: string[];
    availableYears: string[];
    changeYearCount: (_: boolean) => void;
}

const CalculatorYearAmount = ({
    usedYears,
    availableYears,
    changeYearCount,
}: CalculatorYearAmountParams) => (
    <Row gutter={8} align="middle" justify="end">
        <Col><Typography>Tax Year:</Typography></Col>
        <Col className={`year_btn_box ${usedYears.length < 3 && availableYears.length > usedYears.length ? '' : 'btn_box_disabled'}  mr-1`}>
            <Icon
                name="PlusOutlined"
                color={usedYears.length < 3 && availableYears.length > usedYears.length ? 'black' : '#707070'}
                onClick={
                    usedYears.length < 3 && availableYears.length > usedYears.length ? () => {
                        changeYearCount(true);
                    } : undefined
                }
            />
        </Col>

        <Col className={`year_btn_box ${usedYears.length !== 1 ? '' : 'btn_box_disabled'}  mr-1`}>
            <Icon
                name="MinusOutlined"
                color={usedYears.length !== 1 ? 'black' : '#707070'}
                onClick={
                    usedYears.length !== 1 ? () => {
                        changeYearCount(false);
                    } : undefined
                }
            />
        </Col>
    </Row>
);

export default CalculatorYearAmount;
