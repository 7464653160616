import { useMemo } from "react";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import Icon from "../../atoms/Icon";
import { AppDispatch } from "../../../configureStore";
import { setActiveBorrower, setIsAdd, setIsEdit, setLockBorrower } from "../../../redux/reducer/borrower";
import { IBorrower } from "../../../utils/interfaces/borrower";

interface BorrowerButtonParams {
    borrower: IBorrower;
    setOpen: (_: boolean) => void;
}

const BorrowerButton = ({
    borrower,
    setOpen,
}: BorrowerButtonParams) => {
    const count = useMemo(() => borrower.requests, [borrower]);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className="flex-center-center">
            <Tooltip
                title={(count.length > 0 || borrower.verifyTIN) ? "Edit" : "Create"}
                trigger="hover"
                placement="left"
            >
                <div style={{ width: 'fit-content' }}>
                    <Icon
                        size={20}
                        onClick={() => {
                            dispatch(setIsAdd(false));
                            dispatch(setIsEdit(count.length > 0 || borrower.verifyTIN));
                            count.length === 0 && dispatch(setLockBorrower());
                            dispatch(setActiveBorrower(borrower.id));
                            setOpen(true);
                        }}
                        name={(count.length > 0 || borrower.verifyTIN) ? "EditOutlined" : "PlusOutlined"}
                    />
                </div>
            </Tooltip>
            {(count.length === 0 && borrower.verifyTIN) && (
                <Tooltip
                    title="Create"
                    trigger="hover"
                    placement="left"
                >
                    <div style={{ marginLeft: 4, width: 'fit-content' }}>
                        <Icon
                            size={20}
                            onClick={() => {
                                dispatch(setIsEdit(false));
                                dispatch(setIsAdd(true));
                                count.length === 0 && dispatch(setLockBorrower());
                                dispatch(setActiveBorrower(borrower.id));
                                setOpen(true);
                            }}
                            name="PlusOutlined"
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default BorrowerButton;
