import { Table } from 'antd';
import { DateTime } from 'luxon';
import { CalculatorHistory } from '../../utils/interfaces/halcyon360';

interface CalculatorVersionHistoryTableParams {
    versions: CalculatorHistory[];
}

const CalculatorVersionHistoryTable = ({
    versions,
}: CalculatorVersionHistoryTableParams) => (
    <Table
        columns={[
            {
                dataIndex: 'version',
                title: 'Version',
                render: (v: number) => (v || 'Original'),
                sorter: (a, b) => a.version - b.version,
            },
            {
                dataIndex: 'createdTime',
                title: 'Created Time',
                render: (ct: string) => DateTime.fromISO(ct).toFormat('LLL dd, yyyy - h:mm a'),
                sorter: (a, b) => DateTime.fromISO(a.createdTime).toUnixInteger() - DateTime.fromISO(b.createdTime).toUnixInteger(),
            },
            {
                dataIndex: 'createdBy',
                title: 'Created By',
                onFilter: (a, b) => b.createdBy === a,
                filters: versions.map(({ createdBy }) => ({ text: createdBy, value: createdBy })).filter((value, index, self) => index === self.findIndex((t) => t.text === value.text && t.value === value.value)),
            },
            {
                dataIndex: 'notes',
                title: 'Notes',
            },
        ]}
        dataSource={versions}
    />
);

export default CalculatorVersionHistoryTable;
