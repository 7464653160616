import { TPT } from "./interfaces/request";

export function toCamelCase(input: string): string {
    // Split the input string into words
    const words = input.split(/[^a-zA-Z0-9]/);

    // Capitalize the first letter of each word (except the first one)
    const camelCasedWords = words.map((word, index) => {
        if (index === 0) {
            return word.toLowerCase(); // Lowercase the first word
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words to form the camelCased string
    return camelCasedWords.join('');
}

export function textToClipboard(text: string) {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export const STATES: { value: string; label: string }[] = [
    { label: 'AK', value: 'AK' },
    { label: 'AL', value: 'AL' },
    { label: 'AR', value: 'AR' },
    { label: 'AZ', value: 'AZ' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DC', value: 'DC' },
    { label: 'DE', value: 'DE' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'IA', value: 'IA' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'MA', value: 'MA' },
    { label: 'MD', value: 'MD' },
    { label: 'ME', value: 'ME' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MO', value: 'MO' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'NE', value: 'NE' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NV', value: 'NV' },
    { label: 'NY', value: 'NY' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VA', value: 'VA' },
    { label: 'VT', value: 'VT' },
    { label: 'WA', value: 'WA' },
    { label: 'WI', value: 'WI' },
    { label: 'WV', value: 'WV' },
    { label: 'WY', value: 'WY' },
    { label: 'AA', value: 'AA' },
    { label: 'AE', value: 'AE' },
    { label: 'AP', value: 'AP' },
    { label: 'AS', value: 'AS' },
    { label: 'FM', value: 'FM' },
    { label: 'GU', value: 'GU' },
    { label: 'MH', value: 'MH' },
    { label: 'MP', value: 'MP' },
    { label: 'PR', value: 'PR' },
    { label: 'PW', value: 'PW' },
    { label: 'UM', value: 'UM' },
    { label: 'VI', value: 'VI' },
];

export const taxYears = (): number[] => {
    const now = new Date().getFullYear();
    const years = [];

    for (let i = 1; i <= 4; i++) years.push(now - i);

    return years;
};

export const halfStr = (str: string) => {
    if (str) {
        const splitted = str.split(' ');
        const half = splitted.length / 2;

        const second = splitted.splice(half);
        if (second.join(' ') === splitted.join(' ')) {
            return splitted.join(' ');
        }
        return str;
    }
    return undefined;
};

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});
export const formatDollar = (value: number) => dollarFormatter.format(value);

export type RequestStatus = 'Request Submitted' | 'Approved' | 'Request Resubmitted' | 'Processing' | 'Reordered' | 'TIA Sent to IRS' | 'TIA Sent to Borrower' | 'Ready' | 'Cancelled' | 'Action Needed' | 'Multiple' | 'Pending with Borrower' | 'Pending IRS Approval';

export const requestStatuses: RequestStatus[] = [
    'Request Submitted',
    'Request Resubmitted',
    'Processing',
    'Reordered',
    'TIA Sent to IRS',
    'TIA Sent to Borrower',
    'Ready',
    'Cancelled',
    'Action Needed',
];

export const formatTin = (input: string, tpt: TPT = 'Individual'): string => {
    const numericOnly = input.replace(/\D/g, '');

    const formattedString = tpt === 'Individual' ? numericOnly.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") : numericOnly.replace(/(\d{2})(\d{7})/, "$1-$2");

    return formattedString;
};
