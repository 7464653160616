import _ from 'lodash';
import {
    Modal,
} from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../configureStore';
import { AuditEvent, CalculatorHistory } from '../../utils/interfaces/halcyon360';
import { TOGGLE_ANALYZER_HISTORY } from '../../redux/reducer/analyzer';
import CalculatorHistoryTable from './CalculatorHistoryTable';
import CalculatorModalTabs from './CalculatorModalTabs';

const CalculatorHistoryModal = () => {
    const dispatch = useDispatch<AppDispatch>();

    const auditLog = useSelector<RootState, AuditEvent[]>(({ analyzer: { calculation } }) => calculation.auditLog);
    const open = useSelector<RootState, boolean>(({ analyzer: { history } }) => history.open);
    const fieldIds = useSelector<RootState, string[]>(({ analyzer: { history } }) => history.fieldIds);
    const fieldType = useSelector<RootState, 'subtotal' | 'factor' | undefined>(({ analyzer: { history } }) => history.fieldType);
    const divisionId = useSelector<RootState, string | undefined>(({ analyzer: { history } }) => history.divisionId);
    const activeBorrower = useSelector<RootState, string | undefined>(({ analyzer: { activeBorrower: ab } }) => ab);
    const history = useSelector<RootState, CalculatorHistory[]>(({ analyzer: { calculation } }) => calculation?.history || []);

    const events = useMemo<AuditEvent[]>(() => auditLog.filter((al: AuditEvent) => {
        if (al.fieldId === 'root') return fieldIds.length === 0;
        if (al.iii !== activeBorrower) return false;
        if (al.fieldId === 'yearcount' || al.type === 'year') return true;
        if (fieldType === 'subtotal') return (al.fieldId !== divisionId && fieldIds.includes(al.fieldId));
        if (fieldIds.length) return fieldIds.includes(al.fieldId);
        return false;
    }), [auditLog, fieldIds]);

    const setOpen = () => {
        dispatch({ type: TOGGLE_ANALYZER_HISTORY, fieldIds });
    };

    return (
        <Modal
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(); }}
            footer={null}
            width="90%"
        >
            {
                fieldIds.length === 0
                    ? (
                        <CalculatorModalTabs history={history} events={events} />
                    )
                    : (
                        <CalculatorHistoryTable events={events} />
                    )
            }
        </Modal>
    );
};

export default CalculatorHistoryModal;
