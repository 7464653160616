import { Tag } from "antd";

export type TWRole = 'Halcyon Operations Manager' | 'Admin' | 'Operations Manager' | 'Loan Professional';

interface RoleChipParams {
    role: TWRole;
}

const RoleChip = ({
    role,
}: RoleChipParams) => (
    <Tag
        color={role === 'Loan Professional' ? 'blue' : role === 'Operations Manager' ? 'green' : role === 'Admin' ? 'yellow' : 'red'}
    >
        {role}
    </Tag>
);

export default RoleChip;
