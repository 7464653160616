import { Alert, Modal, Tooltip } from "antd";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { RequestStatus } from "../../utils/helpers";
import { IBorrower } from "../../utils/interfaces/borrower";
import ActionNeeded from "./ActionNeeded";
import Icon from "../atoms/Icon";
import CheckAuthorization from "./CheckAuthorization";
import ResendAccountRequest from "./ResendAccountRequest";
import { setActiveBorrower, setCustomerHistory } from "../../redux/reducer/borrower";
import { AppDispatch } from "../../configureStore";

interface StatusCellParams {
    status: RequestStatus;
    item: IBorrower;
    setOpen: (_: boolean) => void;
}

const StatusCell = ({
    status,
    item,
    setOpen,
}: StatusCellParams) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const useStatus = useMemo<RequestStatus | undefined>(() => {
        if (!status) return undefined;
        if (status.startsWith('TIA Sent to') && (item as any).tiaSentToBorrowerTime) {
            return 'Pending with Borrower';
        }
        if (status.startsWith('TIA Sent to')) {
            return 'Pending IRS Approval';
        }
        return status;
    }, [item]);

    if (status === 'Approved') {
        return (
            <div className="flex-center-start">
                <p style={{ margin: 0 }}>{useStatus}</p>
                <Modal
                    open={modalOpen}
                    footer={null}
                    onCancel={() => { setModalOpen(false); }}
                    wrapClassName="pa-0"
                    styles={{
                        content: { padding: 0 },
                    }}
                >
                    <Alert
                        message={!item.encompassStatus ? 'Approval Received' : undefined}
                        description={
                            item.encompassStatus === 'Conflict'
                                ? "We have tried to copy to your Encompass E-Folder but the loan was locked by a user. Please exit and unlock the loan then wait for at least 15 minutes to receive your orders."
                                : item.encompassStatus === 'Forbidden'
                                    ? "The user who ordered this transcript does not have permission to add files to the Encompass E-Folder. Please contact your Encompass Administrator."
                                    : "We have received this transcript from the IRS. We are attempting to transfer it to your Encompass E-Folder now!"
                        }
                        type={item.encompassStatus === 'Conflict' ? "warning" : item.encompassStatus === 'Forbidden' ? "error" : "info"}
                        showIcon
                    />
                </Modal>
                <Icon
                    name="ExclamationCircleOutlined"
                    className="ml-1"
                    color={item.encompassStatus === 'Conflict' ? "#faad14" : item.encompassStatus === 'Forbidden' ? "red" : "blue"}
                    onClick={() => { setModalOpen(true); }}
                />
            </div>
        );
    }
    if (status && !(item?.requests?.length > 0)) return <p style={{ margin: 0 }}>{useStatus}</p>;

    return item.actionReason
        ? <ActionNeeded borrower={item} setOpen={setOpen} />
        : (item.irsIncomeId) ? (
            <div className="flex-center-start row-reverse">
                {(item.hasTIAStatus && item.hasIRS === 'Yes') && (
                    <>
                        <CheckAuthorization id={item.id} />
                        <ResendAccountRequest id={item.id} />
                    </>
                )}
                {
                    (item.history && item.history?.length > 0) && (
                        <Tooltip
                            title="Borrower History"
                            trigger="hover"
                            overlayStyle={{ marginRight: 16 }}
                        >
                            <div style={{ width: 'fit-content' }}>
                                <Icon
                                    size={18}
                                    onClick={() => {
                                        dispatch(setActiveBorrower(item.id));
                                        dispatch(setCustomerHistory(true));
                                    }}
                                    name="HistoryOutlined"
                                />
                            </div>
                        </Tooltip>
                    )
                }
            </div>
        ) : <div />;
};

export default StatusCell;
