import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';

import { AuditEvent, CalculatorHistory } from '../../utils/interfaces/halcyon360';
import CalculatorHistoryTable from './CalculatorHistoryTable';
import CalculatorVersionHistoryTable from './CalculatorVersionHistoryTable';

interface CalculatorModalTabsParams {
    history: CalculatorHistory[];
    events: AuditEvent[];
}

const CalculatorModalTabs = ({
    history,
    events,
}: CalculatorModalTabsParams) => (
    <Tabs>
        <TabPane key="versions" tab="Version History">
            <CalculatorVersionHistoryTable versions={history} />
        </TabPane>
        <TabPane key="changes" tab="Changes">
            <CalculatorHistoryTable events={events} />
        </TabPane>
    </Tabs>
);

export default CalculatorModalTabs;
