import { useMemo } from 'react';
import { Tabs } from 'antd';

import { TaxPayer } from '../../utils/interfaces/analyzer';
import { halfStr } from '../../utils/helpers';

interface BorrowerSelectorParams {
    value: any;
    onChange: (val: any) => void;
    borrowers: TaxPayer[];
}

const BorrowerSelector = ({
    value,
    onChange,
    borrowers,
}: BorrowerSelectorParams) => {
    const items = useMemo(() => {
        const its = borrowers.map((brwr, idx) => ({ key: brwr.id, label: `B${idx + 1}. ${halfStr(brwr.name)}`, children: (<div style={{ display: 'none' }} />) }));
        if (borrowers.length > 1) its.push({ key: 'all', label: 'All', children: (<div style={{ display: 'none' }} />) });
        return its;
    }, [borrowers]);
    return (
        <Tabs
            className="mb-0"
            defaultValue={value}
            onChange={onChange}
            items={items}
        />
    );
};

export default BorrowerSelector;
