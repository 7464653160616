import { ITranscriptRequest } from "../interfaces/create";
import { APIOrigin, CreateOptions, ProxyFile } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class RequestTransaction extends Transaction<CreateOptions> {
    constructor(
        id: string,
        values: ITranscriptRequest,
        origin: APIOrigin,
    ) {
        const { loan } = origin.origin;

        const creation: CreateOptions = {
            encompassBorrowerId: id,
            loanNumber: loan.loanNumber,
            individual: (values.individual ?? []).map((ind) => ({
                tin: ind.tin.length > 11 ? ind.tin.substring(0, 11) : ind.tin,
                firstName: ind.firstName,
                lastName: ind.lastName,
                email: ind.email,
                addressLine1: ind.addressLineOne,
                addressLine2: ind.addressLineTwo,
                city: ind.city,
                state: ind.state,
                postalCode: ind.postalCode,
                taxYears: ind.verifyTIN ? [] : Transaction.getTaxYears(ind.yearList, ind.formList ?? [], ind.multi),
                files: Transaction.getIndividualProxyFiles(ind),
                verifyTIN: ind.verifyTIN,
                eSignEnabled: ind.eSignEnabled,
            })),
            business: (values.business ?? []).map((biz) => ({
                businessName: biz.businessName,
                email: biz.businessEmail,
                addressLine1: biz.addressLineOne,
                addressLine2: biz.addressLineTwo,
                city: biz.city,
                state: biz.state,
                postalCode: biz.postalCode,
                firstName: biz.firstName,
                lastName: biz.lastName,
                title: biz.title,
                eSignEnabled: biz.eSignEnabled,
                ein: biz.ein.length > 10 ? biz.ein.substring(0, 10) : biz.ein,
                taxYears: biz.verifyTIN ? [] : Transaction.getTaxYears(biz.yearList, biz.formList ?? [], biz.multi),
                files: Transaction.getBusinessProxyFiles(biz),
                isSamePerson: biz.isSamePerson || false,
                verifyTIN: biz.verifyTIN,
            })),
            loanProfessional: origin.origin.originatingParty.pointOfContact,
            contacts: loan.contacts || [],
            channel: loan.channel,
            hasIRSAccount: typeof values.hasIRS === 'string' ? values.hasIRS === 'Yes' : values.hasIRS || false,
            hasPOA: false,
            type: "IRS Wage and Income",
            requestType: "Request Created",
            primaryBorrower: loan.applications[0].id,
            instanceId: origin.instanceId,
        };

        const proxyFiles: ProxyFile[] = creation.individual.flatMap(({ files }) => files).concat(creation.business.flatMap(({ files }) => files));
        super(
            id,
            creation,
            proxyFiles,
        );
    }
}
