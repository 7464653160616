import {
    Button,
    Modal,
    Row,
    Typography,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../atoms/Icon';
import { AppDispatch, RootState } from '../../configureStore';
// import { Me } from '../../redux/reducer/user';
import { saveCalculation } from '../../redux/reducer/analyzer';
import { Calculation, FormHash } from '../../utils/interfaces/halcyon360';
import { TransactionOrigin } from '../../utils/interfaces/encompass';

interface IncomeCalculationHeaderParams {
    isLocked: boolean;
    createdBy?: string;
    loanNumber: string;
}

const IncomeCalculationHeader = ({
    isLocked,
    createdBy,
    loanNumber,
}: IncomeCalculationHeaderParams) => {
    const [unlockOpen, setUnlockOpen] = useState<boolean>(false);
    const [colorsOpen, setColorsOpen] = useState<boolean>(false);

    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    // const me = useSelector<RootState, Me | undefined>(({ user: { user: u } }) => u);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['analyzer/SAVE_CALCULATORS']);
    const calcCur = useSelector<RootState, Calculation>(({ analyzer: { calculation } }) => calculation);
    const formHashes = useSelector<RootState, FormHash>(({ analyzer }) => analyzer.formHashes);

    const dispatch = useDispatch<AppDispatch>();

    return (
        <>
            <Modal
                open={unlockOpen}
                title="Unlock File"
                onCancel={() => {
                    setUnlockOpen(false);
                }}
                okText="Unlock"
                okButtonProps={{
                    disabled: false, // me?.email !== createdBy,
                    loading,
                    onClick: () => {
                        dispatch(saveCalculation({
                            input: {
                                OriginId: txnOrgn?.id || '',
                                PartnerAccessToken: txnOrgn?.partnerAccessToken || '',
                                calculator: {
                                    calculator: calcCur,
                                    loanNumber,
                                    lockFile: false,
                                    unlockFile: true,
                                    oldId: calcCur.calculatorId,
                                    updates: [
                                        {
                                            date: new Date(),
                                            fieldId: 'root',
                                            iii: 'root',
                                            type: 'lock',
                                            value: false,
                                        },
                                    ],
                                    formHashes,
                                    name: calcCur.name,
                                },
                            },
                            onSuccess: () => { setUnlockOpen(false); },
                        }));
                    },
                }}
            >
                <h4 style={{ fontSize: 16 }}>
                    This file was locked by
                    {' '}
                    {createdBy}
                </h4>
                {/* {me?.email === createdBy && (
                    <h4 style={{ fontSize: 14 }}>
                        Would you like to unlock it?
                    </h4>
                )} */}
            </Modal>
            <Modal
                open={colorsOpen}
                footer={null}
                onCancel={() => { setColorsOpen(false); }}
                title="Colors Info"
            >
                <ul className="colors-list">
                    <li><div className="custom-bullet yellow">Sections highlighted in yellow need to be filled in manually, as they are not available on IRS Transcripts</div></li>
                    {/* <li><div className="custom-bullet purple">Sections highlighted in purple are filled from Tax returns</div></li> */}
                    <li><div className="custom-bullet edited-bullet">Red indicator on a field shows that the field has been manually edited</div></li>
                </ul>
            </Modal>
            <Row justify="space-between">
                <Row justify="start" align="middle">
                    <Typography.Title level={4} className="mb-0 mr-2">Income Analyzer</Typography.Title>
                    <Icon
                        name={isLocked ? 'LockOutlined' : 'UnlockOutlined'}
                        size="large"
                        onClick={isLocked ? () => {
                            setUnlockOpen(true);
                        } : undefined}
                    />
                    {isLocked && (
                        <Typography
                            style={{
                                fontSize: '13px',
                                textAlign: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            This file is locked
                        </Typography>
                    )}
                </Row>
                <Row justify="end" align="middle">
                    <Button
                        icon={(<Icon name="ExclamationCircleOutlined" />)}
                        onClick={() => { setColorsOpen(!colorsOpen); }}
                    >
                        Colors Info
                    </Button>
                </Row>
            </Row>
        </>
    );
};

export default IncomeCalculationHeader;
