import { Button, Col, Form, FormInstance, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { IBorrower } from "../../utils/interfaces/borrower";
import FormItem from "../atoms/FormItem";
import IndividualTranscripts from "../organisms/IndividualTranscripts";
import HasIRS from "../molecules/HasIRS";
import BusinessTranscripts from "../organisms/BusinessTranscripts";
import { BusinessRequest, ITranscriptRequest } from "../../utils/interfaces/create";
import { setIsAdd } from "../../redux/reducer/borrower";

interface OrderTranscriptsParams {
    form: FormInstance<ITranscriptRequest>;
    handleSubmit: (values: ITranscriptRequest, isReorder?: boolean) => void;
    handleClose: () => void;
}

const OrderTranscripts = ({
    form,
    handleSubmit,
    handleClose,
}: OrderTranscriptsParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { activeBorrower, borrowers } }) => (activeBorrower ? borrowers[activeBorrower] : undefined));
    const enabled = useSelector<RootState, boolean>(({ borrower: brrw }) => Boolean(brrw.activeBorrower));
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const isDefaultNo = useSelector<RootState, boolean>(({ encompass: { origin } }) => origin?.isDefaultNo || false);
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const loanNumber = useSelector<RootState, string | undefined>(({ encompass }) => encompass.loanNumber);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/CREATE_TRANSACTION'] || status.success['encompass/CREATE_TRANSACTION']);

    const [bizReady, setBizReady] = useState<boolean>(false);
    const [openFirst, setOpenFirst] = useState<boolean>(false);

    useEffect(() => {
        const adding = Boolean(!isEdit && (borrower?.hasRequest || borrower?.verifyTIN));
        dispatch(setIsAdd(adding));
        const ind = form.getFieldValue('individual');
        if (isEdit) {
            if (borrower?.taxPayerType === 'Individual') {
                ind[0] = {
                    irsIncomeId: borrower?.irsIncomeId,
                    firstName: borrower?.firstName,
                    lastName: borrower?.lastName,
                    tin: borrower?.tin,
                    email: borrower?.email,
                    addressLineOne: borrower?.addressLineOne,
                    addressLineTwo: borrower?.addressLineTwo,
                    city: borrower?.city,
                    state: borrower?.state,
                    postalCode: borrower?.postalCode,
                    yearList: [0],
                    multi: 'Last 2 Years',
                    transcriptAuthorization: [{ name: borrower?.transcriptAuthorizationPdfFilename }],
                    governmentIssuedPhotoId: [{ name: borrower?.governmentPhotoIdFilename }],
                    selfTakenPicture: [{ name: borrower?.selfTakenPictureFilename }],
                    eSignEnabled: borrower?.eSignEnabled,
                };
                form.setFieldValue('individual', ind);
            } else {
                const bizes: BusinessRequest[] = [{
                    businessEmail: borrower?.email || borrower?.businessInformation?.email || '',
                    businessName: borrower?.businessName || borrower?.name || borrower?.businessInformation?.businessName || borrower?.businessInformation?.name || '',
                    addressLineOne: borrower?.addressLineOne,
                    addressLineTwo: borrower?.addressLineTwo,
                    city: borrower?.city,
                    state: borrower?.state,
                    postalCode: borrower?.postalCode,
                    firstName: borrower?.firstName,
                    lastName: borrower?.lastName,
                    title: borrower?.title,
                    eSignEnabled: borrower?.eSignEnabled,
                    irsIncomeId: borrower?.irsIncomeId,
                    ein: borrower?.tin || borrower?.businessInformation?.ein || '',
                    yearList: [0],
                    multi: 'Last 2 Years',
                    businessTranscriptAuthorization: [{ name: borrower?.transcriptAuthorizationPdfFilename || borrower?.businessInformation?.transcriptAuthorizationPdfFilename || '' }],
                    businessGovernmentIssuedPhotoId: [{ name: borrower?.governmentPhotoIdFilename || borrower?.businessInformation?.governmentPhotoIdFilename || '' }],
                    formList: [],
                    isSamePerson: borrower?.businessInformation?.isSamePerson,
                    verifyTIN: borrower?.verifyTIN || false,
                }];

                form.setFieldValue('business', bizes);
            }
        } else if (adding) {
            if (borrower?.taxPayerType === 'Individual') {
                ind[0] = {
                    irsIncomeId: borrower?.irsIncomeId,
                    firstName: borrower?.firstName,
                    lastName: borrower?.lastName,
                    tin: borrower?.tin,
                    email: borrower?.email,
                    addressLineOne: borrower?.addressLineOne,
                    addressLineTwo: borrower?.addressLineTwo,
                    city: borrower?.city,
                    state: borrower?.state,
                    postalCode: borrower?.postalCode,
                    yearList: [0],
                    multi: 'Last 2 Years',
                    eSignEnabled: borrower?.eSignEnabled,
                };
                form.setFieldValue('individual', ind);
            } else {
                const bizes: BusinessRequest[] = [{
                    businessEmail: borrower?.email || borrower?.businessInformation?.email || '',
                    businessName: borrower?.businessName || borrower?.name || borrower?.businessInformation?.businessName || borrower?.businessInformation?.name || '',
                    irsIncomeId: borrower?.irsIncomeId,
                    ein: borrower?.tin || borrower?.businessInformation?.ein || '',
                    addressLineOne: borrower?.addressLineOne,
                    addressLineTwo: borrower?.addressLineTwo,
                    city: borrower?.city,
                    state: borrower?.state,
                    postalCode: borrower?.postalCode,
                    firstName: borrower?.firstName,
                    lastName: borrower?.lastName,
                    title: borrower?.title,
                    eSignEnabled: borrower?.eSignEnabled,
                    yearList: [0],
                    multi: 'Last 2 Years',
                    businessTranscriptAuthorization: [{ name: borrower?.transcriptAuthorizationPdfFilename || borrower?.businessInformation?.transcriptAuthorizationPdfFilename || '' }],
                    businessGovernmentIssuedPhotoId: [{ name: borrower?.governmentPhotoIdFilename || borrower?.businessInformation?.governmentPhotoIdFilename || '' }],
                    formList: [],
                    isSamePerson: borrower?.businessInformation?.isSamePerson,
                    verifyTIN: borrower?.verifyTIN || false,
                }];
                form.setFieldValue('business', bizes);
            }
        } else if (borrower) {
            ind[0] = {
                firstName: borrower?.firstName,
                lastName: borrower?.lastName,
                tin: borrower?.tin,
                email: borrower?.email,
                addressLineOne: borrower?.addressLineOne,
                addressLineTwo: borrower?.addressLineTwo,
                city: borrower?.city,
                state: borrower?.state,
                postalCode: borrower?.postalCode,
                yearList: [0],
                multi: 'Last 2 Years',
                eSignEnabled: borrower?.eSignEnabled,
            };
            form.setFieldValue('individual', ind);

            const bizes: BusinessRequest[] = [];

            form.setFieldValue('business', bizes);
            setOpenFirst(true);
        }
    }, [borrower]);

    return (
        <Form
            form={form}
            initialValues={{
                ...borrower,
                hasIRS: borrower ? borrower.hasIRS === 'Yes' : !isDefaultNo,
                loanNumber,
                individual: [],
                business: [],
            }}
            onFinish={(values) => {
                handleSubmit(values, isAdd);
            }}
            onChange={() => {
                const vals = form.getFieldsValue();
                if (vals?.individual?.length === 0 && vals?.business?.length) {
                    setBizReady(vals.business.every((biz) => biz?.businessName && biz?.ein && ((biz?.formList?.length && biz?.yearList?.length) || biz.verifyTIN)));
                } else {
                    setBizReady(false);
                }
            }}
            onError={(err) => { console.error(err); }}
        >
            <Row gutter={8} style={{ marginTop: 16, display: 'none' }}>
                <Col span={12}>
                    <FormItem compact disabled={Boolean(loanNumber)} label="Loan Number" />
                </Col>
            </Row>

            <HasIRS hide={isAdd || (isEdit && borrower?.taxPayerType === 'Business')} />
            <IndividualTranscripts
                display={borrower?.taxPayerType === 'Individual' || !(isEdit || isAdd)}
                remove={(idx: number) => {
                    const inds: any[] = form.getFieldValue('individual');
                    inds.splice(idx, 1);
                    form.setFieldValue('individual', inds);
                }}
                openFirst={openFirst || (isAdd || isEdit)}
                setOpenFirst={setOpenFirst}
            />
            <BusinessTranscripts
                display={borrower?.taxPayerType === 'Business' || !(isEdit || isAdd)}
                remove={(idx: number) => {
                    const biz: any[] = form.getFieldValue('business');
                    biz.splice(idx, 1);
                    form.setFieldValue('business', biz);
                }}
            />

            <Row className="mt-2">
                <Button loading={loading} disabled={!enabled && !bizReady} className="mr-1" type="primary" htmlType="submit">
                    Submit
                </Button>
                {/* <Button type="dashed" onClick={() => { console.log(form.getFieldsError()); }}>
                    Test
                </Button> */}
                <Button onClick={() => { handleClose(); }}>
                    Close
                </Button>
            </Row>
        </Form>
    );
};

export default OrderTranscripts;
