import { Table } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
    AuditEvent,
    AuditType,
    CalculatorHistory,
    Fields,
    FlagActiveEvent,
    YearCountEvent,
} from '../../utils/interfaces/halcyon360';
import { RootState } from '../../configureStore';
import { formatDollar } from '../../utils/helpers';

interface CalculatorHistoryTableParams {
    events: AuditEvent[];
}

const CalculatorHistoryTable = ({
    events,
}: CalculatorHistoryTableParams) => {
    const fields = useSelector<RootState, Fields>(({ analyzer: { calculation: c, activeBorrower: ab } }) => c.data[ab || ''].fields);
    const history = useSelector<RootState, { [id: string]: CalculatorHistory }>(({ analyzer: { calculation: calc } }) => calc.history.reduce((a, c) => ({ ...a, [c.calculatorId]: c }), {}));

    return (
        <Table
            rowKey={({ changeId, fieldId: keyL, year }) => `${changeId}${keyL}${year || ''}`}
            columns={[
                {
                    dataIndex: 'time',
                    title: 'Time',
                    width: 180,
                    render: (val: string) => new Date(val).toLocaleString(),
                },
                {
                    dataIndex: 'changeId',
                    title: 'Edited By',
                    ellipsis: true,
                    render: (changeId) => history?.[changeId]?.createdBy,
                },
                {
                    dataIndex: 'fieldId',
                    title: 'Field',
                    ellipsis: true,
                    render: (fieldId: string, { type }) => (type === 'factor' ? fields?.[fieldId]?.subtotal?.multiplyTitle : fields?.[fieldId]?.title),
                },
                {
                    dataIndex: 'year',
                    width: 64,
                    title: 'Year',
                },
                {
                    dataIndex: 'type',
                    title: 'Event',
                    render: (val: AuditType, record: AuditEvent) => {
                        switch (val) {
                            case 'factor':
                                return 'Factor value updated';
                            case 'created':
                                return 'Analyzer created';
                            case 'override':
                                return 'Value Updated';
                            case 'year':
                                return `${record.fieldId.endsWith('0') ? 'First' : 'Second'} Year Changed`;
                            case 'name':
                                return 'Name Added';
                            case 'filename':
                                return 'File Renamed';
                            case 'yearcount':
                                return (record as unknown as YearCountEvent).oldValue > (record as unknown as YearCountEvent).value ? 'Removed Year' : 'Added Year';
                            case 'flag-multiplier':
                                return 'Notice factor changed';
                            case 'flag-active':
                                return `Notice factor ${(record as unknown as FlagActiveEvent).value ? 'added' : 'removed'}`;
                            case 'business':
                            default: return val;
                        }
                    },
                },
                {
                    dataIndex: 'oldValue',
                    title: 'Previous Value',
                    render: (val: string | number, { type }) => (
                        type === 'override'
                            ? Number.isNaN(Number(val)) ? 'N/A' : formatDollar(Number(val))
                            : type === 'factor'
                                ? Number.isNaN(Number(val)) ? 'N/A' : `${val}%`
                                : type === 'flag-active' ? val ? 'Active' : 'Inactive'
                                    : type === 'yearcount' ? `${val} Year${val === 1 ? '' : 's'}`
                                        : val
                    ),
                },
                {
                    dataIndex: 'value',
                    title: 'New Value',
                    render: (val: string | number, { type }) => (
                        type === 'override'
                            ? Number.isNaN(Number(val)) ? 'N/A' : formatDollar(Number(val))
                            : type === 'factor'
                                ? Number.isNaN(Number(val)) ? 'N/A' : `${val}%`
                                : type === 'flag-active' ? val ? 'Active' : 'Inactive'
                                    : type === 'yearcount' ? `${val} Year${val === 1 ? '' : 's'}`
                                        : val
                    ),

                },
            ]}
            dataSource={events}
        />
    );
};

export default CalculatorHistoryTable;
