import { Row } from "antd";
import Icon from "../atoms/Icon";

interface AddTranscriptParams {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onRemove?: () => void;
    isOpen?: boolean
    add?: boolean;
    isBusiness?: boolean;
    businessName?: string;
}

const AddTranscript = ({
    add = false,
    onClick,
    onRemove,
    isOpen = false,
    isBusiness = false,
    businessName,
}: AddTranscriptParams) => (
    <Row
        className={`add-transcript-row mt-2 ${isOpen ? '' : 'mb-2'}`}
        onClick={(e) => { e.stopPropagation(); onClick(e); }}
        justify="space-between"
    >
        <Row>
            {add && (
                <Icon
                    className="mr-1"
                    name="PlusOutlined"
                />
            )}
            <p className={add ? '' : 'ml-1'}>{isBusiness ? "Business" : "Individual"}{businessName && ` - ${businessName}`}</p>
        </Row>
        <Row>
            {!add && (
                <Icon
                    className="mr-1"
                    name="UpOutlined"
                    rotate={isOpen ? 0 : 180}
                />
            )}
            {onRemove && (
                <Icon
                    onClick={(e) => { e?.stopPropagation(); onRemove(); }}
                    className="mr-1"
                    name="CloseOutlined"
                    color="red"
                />
            )}
        </Row>
    </Row>
);

export default AddTranscript;
