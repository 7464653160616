// eslint-disable-next-line import/no-cycle
import { FormsRequested } from './analyzer';

export interface Tints { [id: string]: boolean; }

export interface FormHash { [irsIncomeId: string]: string }

export interface Flag {
    id: string;
    title: string;
    multiplier: number;
    year: string;
    active: boolean;
    location: string[];
}

export interface FlagWithValue extends Flag {
    value?: number;
    newValue?: number;
}

export interface YearFlag {
    [id: string]: Flag;
}

export interface YearFlags {
    [year: string]: YearFlag;
}

export type Operation = 'add' | 'subtract' | 'multi' | 'none';

export interface IValueYear {
    value?: number;
    override?: number;
    flag?: Flag[];
}

export interface IValue {
    [taxYear: string]: IValueYear;
}

export interface Division {
    id: string;
    oId?: string;
    children?: Division[];
    options?: Division[];
    choices?: Division[];
    index?: number;
}

export interface Summary {
    income: number;
    period: 'monthly' | 'annual';
    total: number;
    totals: { [year: string]: number; };
    months: number;
    deductions?: number;
    percent?: number;
}

export type AuditType = 'factor' | 'override' | 'business' | 'year' | 'name' | 'filename' | 'yearcount' | 'flag-multiplier' | 'flag-active' | 'created' | 'forms' | 'lock' | 'field-add' | 'field-remove';

export interface CurrentAudit {
    type: AuditType;
    date: Date;
    iii: string;
    fieldId: string;
    year?: string;
    value: string | number | boolean;
    flagId?: string;
}

export interface Audit {
    type: AuditType;
    time: string;
    changeId: string;
    fieldId: string;
    year: string;
    value: string | number | boolean;
    iii: string;
}

export interface CreateEvent extends Audit {
    type: 'created'
}

export interface ValueAudit extends Audit {
    oldValue: string | number | boolean;
    value: string | number | boolean;
}

export interface FormEvent extends Audit {
    type: 'override';
    formType: string;
    editType: 'add' | 'remove';
}

export interface YearCountEvent extends ValueAudit {
    type: 'yearcount';
}

export interface OverrideEvent extends ValueAudit {
    type: 'override';
}

export interface FactorEvent extends ValueAudit {
    type: 'factor';
}

export interface BusinessEvent extends Audit {
    type: 'business';
}

export interface FileNameEvent extends ValueAudit {
    type: 'filename';
}

export interface NameEvent extends ValueAudit {
    type: 'name';
}

export interface YearEvent extends Audit {
    type: 'year';
}

export interface FormsEvent extends Audit {
    type: 'forms';
}

export interface FlagMultiEvent extends ValueAudit {
    type: 'flag-multiplier';
    flagId: string;
}

export interface FlagActiveEvent extends ValueAudit {
    type: 'flag-active';
    flagId: string;
}

export interface LockEvent extends ValueAudit {
    type: 'lock'
}

export interface FieldAddEvent extends Audit {
    type: 'field-add',
}

export interface FieldRemoveEvent extends Audit {
    type: 'field-remove',
    oldTitle?: string;
}

export type AuditEvent = CreateEvent | FactorEvent | OverrideEvent | BusinessEvent | YearEvent | FileNameEvent | NameEvent | YearCountEvent | FlagActiveEvent | FlagMultiEvent | FormsEvent | LockEvent | FieldAddEvent | FieldRemoveEvent;

export interface AuditById {
    [id: string]: {
        children: AuditById;
        items: AuditEvent[];
    }
}

export interface Removables { [id: string]: { [year: string]: boolean } }

export interface CalculatorHistory {
    calculatorId: string;
    createdBy: string;
    createdTime: string
    notes?: string;
    version: number;
}

export interface Field {
    id: string;
    multifield?: string;
    isSupplement?: boolean;
    isOptional?: boolean;
    index?: number;
    level?: number;
    indent?: number;
    notDollar?: boolean;
    removable?: boolean
    title: string;
    hide?: boolean;
    operation: Operation;
    values: IValue;
    flagIds?: { id: string; active: boolean; }[];
    subtotal?: {
        isCents?: boolean;
        factor: IValue;
        factorValue?: IValue;
        isDivisor?: boolean;
        defaultFactor?: number;
        factorPath?: string;
        factorIndent?: number;
        fixedFactor?: boolean;
        values: IValue;
        title?: string;
        multiplyTitle: string;
    };
    prefix?: {
        title: string;
        years?: boolean;
        input?: {
            label: string;
            value?: string | number;
            valuePath?: string;
            businessName?: string;
            override?: string;
        }
    };
    valueTitle?: string;
    path?: string | { path: string; operation?: Operation; }[];
}

export interface Fields {
    [id: string]: Field;
}

export interface IncomeCalculation {
    divisions: Division[];
    fields: Fields;
    years: string[];
    usedYears: string[];
    total: number;
    summary: Summary;
    forms: FormsRequested[];
    flags?: {
        [id: string]: Flag;
    };
}

export interface AuditLogById { [iii: string]: { [fieldId: string]: boolean; }; }
export interface Calculation {
    id?: string;
    calculatorId: string;
    name: string;
    version: number;
    type: 'Custom' | 'Form 91' | 'Form 1084';
    auditLog: AuditEvent[];
    auditLogById: AuditLogById;
    createdBy?: string;
    createdTime: string;
    loanNumber?: string;
    versionHistory: string[];
    isDefault?: boolean;
    isLocked?: boolean;
    notes?: string;
    history: CalculatorHistory[];
    formHashes: FormHash;
    data: {
        [irsIncomeId: string]: IncomeCalculation;
    }
}

export interface BorrowerCalculation {
    [irsIncomeId: string]: Calculation;
}

export interface SaveCalculator {
    OriginId: string;
    PartnerAccessToken: string;
    calculator: {
        calculator: Calculation;
        loanNumber: string;
        oldId?: string;
        lockFile: boolean;
        unlockFile?: boolean;
        name: string;
        notes?: string;
        formHashes: FormHash;
        updates: CurrentAudit[];
    }
}

export interface SaveCalculatorResponse {
    data: {
        calculator: Calculation;
        success: boolean;
    }
}

export const defaultCalculation: Calculation = {
    calculatorId: 'default',
    name: 'Halcyon',
    formHashes: {},
    version: 0,
    isLocked: false,
    type: 'Custom',
    createdTime: '',
    versionHistory: [],
    auditLogById: {},
    auditLog: [],
    history: [],
    data: {
        all: {
            divisions: [],
            fields: {},
            years: [],
            forms: [],
            usedYears: [],
            total: 0,
            summary: {
                income: 0,
                period: 'monthly',
                total: 0,
                totals: {},
                months: 24,
            },
        },
    },
};
