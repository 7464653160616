import _ from 'lodash';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../configureStore';
import { Division } from '../../utils/interfaces/halcyon360';
import { UPDATE_CALCULATION_NAME } from '../../redux/reducer/analyzer';

interface CalculatorInputTextParams {
    division: Division;
}

const CalculatorInputText = ({
    division,
}: CalculatorInputTextParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const value = useSelector<RootState, string | undefined>(({ analyzer: { calculation: c, activeBorrower: a } }) => (c.data[a || ''].fields[division.id].prefix?.input?.value ? String(c.data[a || ''].fields[division.id].prefix?.input?.value) : undefined));

    return (
        <Input
            className="ml-1"
            style={{
                maxWidth: '240px',
            }}
            onChange={(e) => {
                dispatch({
                    type: UPDATE_CALCULATION_NAME,
                    value: e.target.value,
                    divisionId: division.id,
                });
            }}
            size="small"
            value={value}
        />
    );
};

export default CalculatorInputText;
