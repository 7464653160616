import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { Division, Field } from '../../../utils/interfaces/halcyon360';
import { RootState } from '../../../configureStore';
import Icon from '../../atoms/Icon';
import CalculatorInput from '../../atoms/CalculatorInput';
import { flatDiv } from '../../../utils/halcyon360.ts';

interface CalculatorRowParams {
    division: Division;
    className?: string;
    location: string;
    setHistoryOpen: (l: string[], divisionId: string, fieldType?: 'subtotal' | 'factor') => void;
    indent?: number;
    isSubtotal?: boolean;
    isFactor?: boolean;
}

const CalculatorRow = ({
    division,
    className,
    location,
    setHistoryOpen,
    isSubtotal,
    isFactor,
    indent = 0,
}: CalculatorRowParams) => {
    const hasChanges = useSelector<RootState, boolean>(({ analyzer: { activeBorrower, calculation: cac } }) => (isFactor ? cac.auditLogById[activeBorrower || '']?.[division.id] : flatDiv(division).some((af) => (isSubtotal ? (af !== division.id && cac.auditLogById[activeBorrower || '']?.[af]) : cac.auditLogById[activeBorrower || '']?.[af]))));
    const field = useSelector<RootState, Field>(({ analyzer: { activeBorrower, calculation: c } }) => c.data[activeBorrower || ''].fields[division.id]);
    const usedYears = useSelector<RootState, string[]>(({ analyzer: { activeBorrower, calculation: c } }) => c.data[activeBorrower || ''].usedYears);
    const isTotal = useMemo<boolean>(() => (((division?.children?.length || 0) + (division?.choices?.length || 0)) > 0 && !isFactor), [field]);

    return (
        <Row gutter={8} justify="space-between" align="middle" className={`${className ?? ''} analyzer_row pa-2 arow-${indent + (isFactor ? (field?.subtotal?.factorIndent || 0) : 0)}`}>
            <Col xs={16} className="flex-start-between">
                <Typography>{isFactor ? field.subtotal?.multiplyTitle : isSubtotal ? field.subtotal?.title : field.title}</Typography>
            </Col>
            <Col xs={8}>
                <Row gutter={8} justify="space-between" align="middle">
                    <Col xs={2} style={{ justifyContent: 'end', display: 'flex', alignItems: 'end' }}>
                        {hasChanges && (
                            <Icon
                                size="small"
                                name="HistoryOutlined"
                                color="#1677FF"
                                onClick={() => {
                                    setHistoryOpen(isFactor ? [division.id] : flatDiv(division), division.id, isSubtotal ? 'subtotal' : isFactor ? 'factor' : undefined);
                                }}
                            />
                        )}
                    </Col>
                    {usedYears.map((v, idx) => (
                        <Col
                            key={`${location}-${idx}-${v}`}
                            xs={Math.floor(22 / (usedYears.length || 1))}
                        >
                            <CalculatorInput
                                noEdit={isTotal || Boolean(field.subtotal?.fixedFactor)}
                                isSubtotal={isSubtotal}
                                isFactor={isFactor}
                                location={location.split('|')}
                                valueIndex={idx}
                                division={division}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default CalculatorRow;
