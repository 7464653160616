import { useEffect, useState } from 'react';
import { Col, FormListFieldData, Row, Form, Checkbox } from "antd";
import { useSelector } from "react-redux";
import AddTranscript from "./AddTranscript";
import BorrowerInformation from "./BorrowerInformation";
import AddressInformation from "./AddressInformation";
import FormTypes from "./FormTypes";
import DocumentUpload from "./DocumentUpload";
import { RootState } from "../../configureStore";
import FormItem from "../atoms/FormItem";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

interface IndividualTranscriptParams {
    fields: FormListFieldData[];
    change: (action: 'remove' | 'add', toRemove?: FormListFieldData) => void;
    open: Set<number>;
    onOpenChange: (field: FormListFieldData) => void;
    className?: string;
}

const IndividualTranscript = ({
    fields,
    change,
    open,
    onOpenChange,
    className = '',
}: IndividualTranscriptParams) => {
    const eSign = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => borrowers[activeBorrower || '']?.eSignEnabled || false);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEncompass = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.loanNumber));
    const hasRequest = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => (borrowers[activeBorrower || ""]?.requests?.length > 0 && borrowers[activeBorrower || ""].taxPayerType === "Individual"));
    const vTin = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => borrowers[activeBorrower || '']?.verifyTIN || false);
    const { isSmall, isMedium } = useWindowDimensions();
    const [eSignEnabled, setIsChecked] = useState<boolean>(false);
    const eSignRequired = useSelector<RootState, boolean>(({ encompass: { origin } }) => Boolean(origin?.eSignEnabledTenant));
    useEffect(() => {
        setIsChecked(eSign);
    }, []);

    return (
        <div className={className}>
            <div>
                {fields.map((field, idx) => (
                    <div key={`individual-txpt-${idx}`}>
                        <div className="none"><FormItem label="irsIncomeId" index={field.key} /></div>
                        {(!isEdit && !isAdd) && (
                            <AddTranscript
                                onClick={() => { onOpenChange(field); }}
                                onRemove={(isAdd || isEdit) ? undefined : () => { change('remove', field); }}
                                isOpen={open.has(field.name)}
                            />
                        )}
                        <div style={{ display: !open.has(field.name) ? 'none' : undefined }}>
                            <Form.Item
                                getValueFromEvent={(ev: any) => {
                                    setIsChecked(ev.target.checked);
                                    return ev.target.checked;
                                }}
                                name={idx != null ? [idx, "eSignEnabled"] : "eSignEnabled"}
                                valuePropName="checked"
                                className="mb-1"
                            // style={{ display: (eSignEnabled) ? 'none' : undefined }}
                            >
                                {eSignRequired && (
                                    <Checkbox>Send to Borrower for Signature</Checkbox>
                                )}
                            </Form.Item>

                            <BorrowerInformation index={field.key} eSignEnabled={eSignEnabled} />
                            <AddressInformation index={field.key} hidden={false} />
                            <Row gutter={8}>
                                <Col className={isEdit ? 'none' : ''} span={12}>
                                    <FormTypes
                                        existing={isAdd}
                                        index={field.key}
                                        type="Individual"
                                    />
                                </Col>
                                <Col
                                    className={(isAdd && (hasRequest || vTin)) ? 'none' : ''}
                                    span={12}
                                >
                                    <DocumentUpload
                                        existing={isAdd && (hasRequest || vTin)}
                                        span={isEdit ? isMedium ? 24 : 10 : isSmall ? 24 : 12}
                                        index={field.key}
                                        isEdit={isEdit}
                                        eSignEnabled={eSignEnabled}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                ))}
            </div>
            {((!isEncompass || fields.length === 0) && (!isEdit && !isAdd)) && (
                <AddTranscript
                    add
                    onClick={() => {
                        change('add');
                    }}
                />
            )}
        </div>
    );
};

export default IndividualTranscript;
