import { Col, Row, Select } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';

interface CalculatorYearSelectsParams {
    usedYears: string[]
    allYears: number[];
    onChange: (e: string, idx: number) => void;
}

const CalculatorYearSelects = ({
    usedYears = [],
    allYears = [],
    onChange,
}: CalculatorYearSelectsParams) => {
    const yearOptions = useMemo<(DefaultOptionType | BaseOptionType)[]>(() => allYears.map<DefaultOptionType | BaseOptionType>((value) => ({ label: value, value })), [allYears]);

    const errorYear = useMemo<{ [index: string]: boolean; }>(() => {
        if (!usedYears) return {};
        const duped = new Set<string>();
        const error: { [index: string]: boolean; } = usedYears.reduce((a, _c, idx) => ({ ...a, [String(idx)]: false }), {});
        usedYears.forEach((yr, idx) => {
            error[String(idx)] = Boolean(idx && Number(usedYears[idx - 1]) !== Number(yr) + 1);
            if (duped.has(String(yr))) {
                error[String(idx)] = true;
            }
            duped.add(String(yr));
        });
        return error;
    }, [usedYears]);

    return (
        <Row gutter={8} justify="space-between" align="middle">
            <Col xs={2} />
            {(usedYears || []).map((yr, idx) => (
                <Col
                    key={`year-select-${yr}-${idx}`}
                    xs={Math.floor(22 / (usedYears.length || 1))}
                    style={{ textAlign: 'center' }}
                >
                    <Select
                        value={usedYears[idx]}
                        style={{ textAlign: 'start' }}
                        className={`year-input w100 mr-1${errorYear[String(idx)] ? ' danger_year-input' : ''}`}
                        options={yearOptions}
                        onChange={(e) => { onChange(e, idx); }}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default CalculatorYearSelects;
